import "./Card.scss";

export const Card: React.FC<{
  color: "primary";
  children?: React.ReactNode;
}> = ({ children, color }) => {
  return (
    <div
      className="Card"
      style={{
        ["--card-color" as any]:
          color === "primary" ? "var(--color-primary)" : "var(--color-primary)",
        ["--card-color-darker" as any]:
          color === "primary"
            ? "var(--color-primary-darker)"
            : "var(--color-primary)",
        ["--card-color-darkest" as any]:
          color === "primary"
            ? "var(--color-primary-darkest)"
            : "var(--color-primary)",
      }}
    >
      {children}
    </div>
  );
};
