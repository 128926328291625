import {
  copyBlobToClipboardAsPng,
  copyTextToSystemClipboard,
} from "../clipboard";
import {
  DEFAULT_EXPORT_PADDING,
  DEFAULT_FILENAME,
  isFirefox,
  MIME_TYPES,
} from "../constants";
import { getNonDeletedElements } from "../element";
import { isFrameLikeElement } from "../element/typeChecks";
import type {
  ExcalidrawElement,
  ExcalidrawFrameLikeElement,
  NonDeletedExcalidrawElement,
} from "../element/types";
import { t } from "../i18n";
import { isSomeElementSelected, getSelectedElements } from "../scene";
import { exportToCanvas, exportToSvg } from "../scene/export";
import type { ExportType } from "../scene/types";
import type { AppState, BinaryFiles } from "../types";
import { cloneJSON } from "../utils";
import { canvasToBlob } from "./blob";
import type { FileSystemHandle } from "./filesystem";
import { fileSave } from "./filesystem";
import { serializeAsJSON } from "./json";
import { getElementsOverlappingFrame } from "../frame";
// Import jspdf - Library for generating pdfs in javascript
import jsPDF from "jspdf";
// Import Watermark Image Logos
import wingmanlogo from "../../../public/wingman-canvas-logo.png";
//import jetsetcoachlogo from "../../../public/jetsetcoach-logo.png";
// Import Axios for fetching IP Address
import axios from "axios";

export { loadFromBlob } from "./blob";
export { loadFromJSON, saveAsJSON } from "./json";

export type ExportedElements = readonly NonDeletedExcalidrawElement[] & {
  _brand: "exportedElements";
};

export const prepareElementsForExport = (
  elements: readonly ExcalidrawElement[],
  { selectedElementIds }: Pick<AppState, "selectedElementIds">,
  exportSelectionOnly: boolean,
) => {
  elements = getNonDeletedElements(elements);

  const isExportingSelection =
    exportSelectionOnly &&
    isSomeElementSelected(elements, { selectedElementIds });

  let exportingFrame: ExcalidrawFrameLikeElement | null = null;
  let exportedElements = isExportingSelection
    ? getSelectedElements(
        elements,
        { selectedElementIds },
        {
          includeBoundTextElement: true,
        },
      )
    : elements;

  if (isExportingSelection) {
    if (
      exportedElements.length === 1 &&
      isFrameLikeElement(exportedElements[0])
    ) {
      exportingFrame = exportedElements[0];
      exportedElements = getElementsOverlappingFrame(elements, exportingFrame);
    } else if (exportedElements.length > 1) {
      exportedElements = getSelectedElements(
        elements,
        { selectedElementIds },
        {
          includeBoundTextElement: true,
          includeElementsInFrames: true,
        },
      );
    }
  }

  return {
    exportingFrame,
    exportedElements: cloneJSON(exportedElements) as ExportedElements,
  };
};

// Added a function to fetch IP Address
const fetchIpAddress = async () => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json"); // Used ipify - external api service
    return response.data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error);
    return "unknown IP";
  }
};

// Added a function to load images before adding watermark
const loadImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = reject;
  });
};

// Added a function to add watermarks to the PDF
const addWatermark = async (
  doc: jsPDF,
  canvasWidth: number,
  canvasHeight: number,
  isDarkMode: boolean,
) => {
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const watermarkText = "JetSetCoach";
  const opacity = 0.2;

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) throw new Error("Canvas context is not available");

  // Calculate font size for watermark text based on canvas size, with a maximum of 54px
  const maxWatermarkFontSize = 54;
  const watermarkFontSize = Math.min(
    maxWatermarkFontSize,
    Math.min(canvasWidth, canvasHeight) / 15,
  );
  ctx.font = `${watermarkFontSize}px serif`;

  const textWidth = ctx.measureText(watermarkText).width;
  const textHeight = watermarkFontSize;
  const diagonalLength = Math.sqrt(
    textWidth * textWidth + textHeight * textHeight,
  );
  canvas.width = diagonalLength;
  canvas.height = diagonalLength;

  ctx.globalAlpha = opacity;
  ctx.font = `${watermarkFontSize}px serif`;
  ctx.fillStyle = isDarkMode ? "white" : "black";
  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate(-Math.PI / 4);
  ctx.fillText(watermarkText, -textWidth / 2, -textHeight / 2);
  ctx.resetTransform();

  const combinedWatermarkDataUrl = canvas.toDataURL("image/png");

  // Added the watermark text at multiple positions
  const numColumns = 3;
  const numRows = 3;
  const columnSpacing = pageWidth / (numColumns + 1);
  const rowSpacing = pageHeight / (numRows + 1);

  for (let i = 1; i <= numColumns; i++) {
    for (let j = 1; j <= numRows; j++) {
      const x = i * columnSpacing - diagonalLength / 2;
      const y = j * rowSpacing - diagonalLength / 2;
      doc.addImage(
        combinedWatermarkDataUrl,
        "PNG",
        x,
        y,
        diagonalLength,
        diagonalLength,
      );
    }
  }
};

// Added a function to get current date and time for footer
const getFooterText = async () => {
  const date = new Date();
  const dateString = date.toLocaleDateString();
  const timeString = date.toLocaleTimeString();
  const ipAddress = await fetchIpAddress();
  const footerText = `The contents of this document are copyright of JetSetCoach Pvt Ltd* intended to be shared only with registered students.\nReaders are prohibited from distributing / cropping / copying this content. It is unlawful to do so and is punishable.\nGenerated on ${dateString} at ${timeString} from IP address ${ipAddress}.`;
  const logo = await loadImage(wingmanlogo);
  return { footerText, logo };
};

export const exportCanvas = async (
  type: Omit<ExportType, "backend">,
  elements: ExportedElements,
  appState: AppState,
  files: BinaryFiles,
  {
    exportBackground,
    exportPadding = DEFAULT_EXPORT_PADDING,
    viewBackgroundColor,
    name = appState.name || DEFAULT_FILENAME,
    fileHandle = null,
    exportingFrame = null,
  }: {
    exportBackground: boolean;
    exportPadding?: number;
    viewBackgroundColor: string;
    name?: string;
    fileHandle?: FileSystemHandle | null;
    exportingFrame: ExcalidrawFrameLikeElement | null;
  },
) => {
  if (elements.length === 0) {
    throw new Error(t("alerts.cannotExportEmptyCanvas"));
  }
  if (type === "svg" || type === "clipboard-svg") {
    const svgPromise = exportToSvg(
      elements,
      {
        exportBackground,
        exportWithDarkMode: appState.exportWithDarkMode,
        viewBackgroundColor,
        exportPadding,
        exportScale: appState.exportScale,
        exportEmbedScene: appState.exportEmbedScene && type === "svg",
      },
      files,
      { exportingFrame },
    );

    if (type === "svg") {
      return fileSave(
        svgPromise.then((svg) => {
          return new Blob([svg.outerHTML], { type: MIME_TYPES.svg });
        }),
        {
          description: "Export to SVG",
          name,
          extension: appState.exportEmbedScene ? "excalidraw.svg" : "svg",
          fileHandle,
        },
      );
    } else if (type === "clipboard-svg") {
      const svg = await svgPromise.then((svg) => svg.outerHTML);
      try {
        await copyTextToSystemClipboard(svg);
      } catch (e) {
        throw new Error(t("errors.copyToSystemClipboardFailed"));
      }
      return;
    }
  }

  const tempCanvas = exportToCanvas(elements, appState, files, {
    exportBackground,
    viewBackgroundColor,
    exportPadding,
    exportingFrame,
  });

  if (type === "png") {
    let blob = canvasToBlob(tempCanvas);

    if (appState.exportEmbedScene) {
      blob = blob.then((blob) =>
        import("./image").then(({ encodePngMetadata }) =>
          encodePngMetadata({
            blob,
            metadata: serializeAsJSON(elements, appState, files, "local"),
          }),
        ),
      );
    }

    return fileSave(blob, {
      description: "Export to PNG",
      name,
      extension: "png",
      fileHandle,
    });
  } else if (type === "pdf") {
    // Added Pdf type export condition
    const canvasWidth = (await tempCanvas).width;
    const canvasHeight = (await tempCanvas).height;
    const orientation = canvasWidth > canvasHeight ? "landscape" : "portrait";

    const extendedCanvas = document.createElement("canvas");
    const footerHeight = 100;
    extendedCanvas.width = canvasWidth;
    extendedCanvas.height = canvasHeight + footerHeight;
    const extendedCtx = extendedCanvas.getContext("2d");
    if (!extendedCtx)
      throw new Error("Extended canvas context is not available");
    extendedCtx.drawImage(await tempCanvas, 0, 0);

    const doc = new jsPDF({
      orientation,
      unit: "mm",
      format: [extendedCanvas.width, extendedCanvas.height],
    });

    const imageData = extendedCanvas.toDataURL("image/png");
    doc.addImage(
      imageData,
      "PNG",
      0,
      0,
      extendedCanvas.width,
      extendedCanvas.height,
    );

    // Added watermark in Pdf
    await addWatermark(
      doc,
      canvasWidth,
      canvasHeight,
      appState.exportWithDarkMode,
    );
    // Added footer text and logo in Pdf
    const { footerText, logo } = await getFooterText();
    const maxFontSize = Math.min(canvasWidth, canvasHeight) / 20;
    const footerFontSize = Math.min(maxFontSize, 28);

    doc.setFontSize(footerFontSize);
    doc.setFont("helvetica", "italic");

    const footerMargin = 20;
    let footerYPos = (await tempCanvas).height + footerMargin;

    const maxLogoWidth = Math.min(canvasWidth / 8, 140);
    const logoWidth = Math.min(maxLogoWidth, 0.5 * canvasWidth);
    const logoHeight = logoWidth * (logo.height / logo.width);

    const textXPos = logoWidth + 20;
    const textWidth = doc.internal.pageSize.getWidth() - textXPos - 10;

    const logoXPos = 10;
    doc.addImage(logo, "PNG", logoXPos, footerYPos, logoWidth, logoHeight);

    const originalTextColor = doc.getTextColor();
    doc.setTextColor(0, 0, 0, 0.6);

    const lines = doc.splitTextToSize(footerText, textWidth);
    const totalTextHeight = lines.length * footerFontSize * 0.5;

    if (totalTextHeight > logoHeight) {
      footerYPos += (totalTextHeight - logoHeight) / 2;
    }

    lines.forEach((line: string, index: number) => {
      const lineHeight = footerFontSize * 0.5;
      const textYPos =
        footerYPos +
        logoHeight / 2 +
        index * lineHeight -
        (lines.length * lineHeight) / 2;
      doc.text(line, textXPos, textYPos, { align: "left" });
    });

    doc.setTextColor(originalTextColor);

    // Generate PDF
    return fileSave(new Blob([doc.output("blob")], { type: MIME_TYPES.pdf }), {
      description: "Export to PDF",
      name: `${name}.pdf`,
      extension: "pdf",
      fileHandle,
    });
  } else if (type === "clipboard") {
    try {
      const blob = canvasToBlob(tempCanvas);
      await copyBlobToClipboardAsPng(blob);
    } catch (error: any) {
      console.warn(error);
      if (error.name === "CANVAS_POSSIBLY_TOO_BIG") {
        throw new Error(t("canvasError.canvasTooBig"));
      }
      if (isFirefox && error.name === "TypeError") {
        throw new Error(
          `${t("alerts.couldNotCopyToClipboard")}\n\n${t(
            "hints.firefox_clipboard_write",
          )}`,
        );
      } else {
        throw new Error(t("alerts.couldNotCopyToClipboard"));
      }
    }
  } else {
    throw new Error("Unsupported export type");
  }
};
